@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');


body {
  --sb-track-color: #10141c;
  --sb-thumb-color: #ff047e;
  --sb-size: 3px;
  background: #10141C;
  font-family: "Space Grotesk";
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}

body::-webkit-scrollbar {
  width: var(--sb-size)
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 30px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 30px;

}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

table {
  --sb-track-color: #10141c;
  --sb-thumb-color: #ff047e;
  --sb-size: 3px;
}

table::-webkit-scrollbar {
  width: var(--sb-size)
}

table::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 30px;
}

table::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 30px;

}

@supports not selector(::-webkit-scrollbar) {
  table {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}